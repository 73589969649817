//
// buttons.scss
//

.btn {
    .mdi {
        &:before {
            line-height: initial;
        }
    }

    .spinner-grow,
    .spinner-border {
        vertical-align: middle;
    }

    &-rounded {
        border-radius: 50%;
    }

    &-xs {
        padding: 0.2rem 0.6rem;
        font-size: 0.75rem;
        border-radius: 0.15rem;
    }

    // Button labels
    &-label {
        margin: -0.55rem 0.9rem -0.55rem -0.9rem;
        padding: 0.6rem 0.9rem;
    }

    &-label-right {
        margin: (-$btn-padding-y) (-$btn-padding-x) (-$btn-padding-y) ($btn-padding-x);
        padding: ($btn-padding-y) ($btn-padding-x);
        display: inline-block;
    }

    i {
        display: inline-block;
    }
}

button:focus {
    outline: none;
}


// Button Soft
@mixin button-variant-soft($bg) {
    --#{$prefix}btn-color: #{$bg};
    --#{$prefix}btn-bg: #{rgba($bg, 0.1)};
    --#{$prefix}btn-border-color: #{transparent};
    --#{$prefix}btn-hover-color: #{$white};
    --#{$prefix}btn-hover-bg: #{$bg};
    --#{$prefix}btn-hover-border-color: #{$bg};
    --#{$prefix}btn-active-color: #{$white};
    --#{$prefix}btn-active-bg: #{$bg};
    --#{$prefix}btn-active-border-color: #{$bg};
    --#{$prefix}btn-disabled-color: #{$bg};
    --#{$prefix}btn-disabled-bg: #{$bg};
    --#{$prefix}btn-disabled-border-color: #{$bg};
    --#{$prefix}btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba($bg, 0.5);
}

@each $color,
$value in $theme-colors {
    .btn-soft-#{$color} {
        @include button-variant-soft($value);
    }
}

// Button White
.btn-white,
.btn-outline-light {
    --#{$prefix}btn-color: var(--#{$prefix}gray-900);
    --#{$prefix}btn-hover-bg: var(--#{$prefix}gray-100);
    --#{$prefix}btn-border-color: var(--#{$prefix}gray-300);
    --#{$prefix}btn-hover-border-color: var(--#{$prefix}gray-100);
}

// Dark Mode Light/Dark button
html[data-bs-theme="dark"] {

    .btn-light {
        --#{$prefix}btn-color: var(--#{$prefix}gray-900);
        --#{$prefix}btn-bg: var(--#{$prefix}gray-300);
        --#{$prefix}btn-border-color: var(--#{$prefix}gray-300);
        --#{$prefix}btn-hover-color: var(--#{$prefix}gray-900);
        --#{$prefix}btn-hover-bg: var(--#{$prefix}gray-300);
        --#{$prefix}btn-hover-border-color: var(--#{$prefix}gray-300);
        --#{$prefix}btn-active-color: var(--#{$prefix}gray-900);
        --#{$prefix}btn-active-bg: var(--#{$prefix}gray-300);
        --#{$prefix}btn-active-border-color: var(--#{$prefix}gray-300);
    }

    .btn-outline-dark {
        --#{$prefix}btn-color: var(--#{$prefix}gray-900);
    }

    .btn-outline-light,
    .btn-outline-dark {
        --#{$prefix}btn-hover-color: var(--#{$prefix}gray-900);
        --#{$prefix}btn-color: var(--#{$prefix}gray-900);
    }

    .btn-soft-dark {
        --#{$prefix}btn-hover-bg: var(--#{$prefix}light);
        --#{$prefix}btn-hover-border-color: var(--#{$prefix}light);
    }
}